/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AsdSicherheitsbriefDto } from '../models/asd-sicherheitsbrief-dto';
import { deleteAsdSicherheitsbrief } from '../fn/asd-sicherheitsbrief-controller/delete-asd-sicherheitsbrief';
import { DeleteAsdSicherheitsbrief$Params } from '../fn/asd-sicherheitsbrief-controller/delete-asd-sicherheitsbrief';
import { DocumentSasUrlDto } from '../models/document-sas-url-dto';
import { downloadUrlAsdSicherheitsbrief } from '../fn/asd-sicherheitsbrief-controller/download-url-asd-sicherheitsbrief';
import { DownloadUrlAsdSicherheitsbrief$Params } from '../fn/asd-sicherheitsbrief-controller/download-url-asd-sicherheitsbrief';
import { getAllAsdSicherheitsbrief } from '../fn/asd-sicherheitsbrief-controller/get-all-asd-sicherheitsbrief';
import { GetAllAsdSicherheitsbrief$Params } from '../fn/asd-sicherheitsbrief-controller/get-all-asd-sicherheitsbrief';
import { ResponseAsdSicherheitsbriefDto } from '../models/response-asd-sicherheitsbrief-dto';
import { uploadAsdSicherheitsbrief } from '../fn/asd-sicherheitsbrief-controller/upload-asd-sicherheitsbrief';
import { UploadAsdSicherheitsbrief$Params } from '../fn/asd-sicherheitsbrief-controller/upload-asd-sicherheitsbrief';

@Injectable({ providedIn: 'root' })
export class AsdSicherheitsbriefControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllAsdSicherheitsbrief()` */
  static readonly GetAllAsdSicherheitsbriefPath = '/documents/asd-sicherheitsbrief';

  /**
   * Get all ASD Sicherheitsbriefs metadata.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAsdSicherheitsbrief()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAsdSicherheitsbrief$Response(params?: GetAllAsdSicherheitsbrief$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseAsdSicherheitsbriefDto>> {
    return getAllAsdSicherheitsbrief(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all ASD Sicherheitsbriefs metadata.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAsdSicherheitsbrief$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAsdSicherheitsbrief(params?: GetAllAsdSicherheitsbrief$Params, context?: HttpContext): Observable<ResponseAsdSicherheitsbriefDto> {
    return this.getAllAsdSicherheitsbrief$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseAsdSicherheitsbriefDto>): ResponseAsdSicherheitsbriefDto => r.body)
    );
  }

  /** Path part for operation `uploadAsdSicherheitsbrief()` */
  static readonly UploadAsdSicherheitsbriefPath = '/documents/asd-sicherheitsbrief';

  /**
   * Upload a ASD Sicherheitsbrief.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAsdSicherheitsbrief()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAsdSicherheitsbrief$Response(params?: UploadAsdSicherheitsbrief$Params, context?: HttpContext): Observable<StrictHttpResponse<AsdSicherheitsbriefDto>> {
    return uploadAsdSicherheitsbrief(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload a ASD Sicherheitsbrief.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAsdSicherheitsbrief$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAsdSicherheitsbrief(params?: UploadAsdSicherheitsbrief$Params, context?: HttpContext): Observable<AsdSicherheitsbriefDto> {
    return this.uploadAsdSicherheitsbrief$Response(params, context).pipe(
      map((r: StrictHttpResponse<AsdSicherheitsbriefDto>): AsdSicherheitsbriefDto => r.body)
    );
  }

  /** Path part for operation `downloadUrlAsdSicherheitsbrief()` */
  static readonly DownloadUrlAsdSicherheitsbriefPath = '/documents/asd-sicherheitsbrief/download/{uuid}';

  /**
   * provides download url for ASD Sicherheitsbrief.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadUrlAsdSicherheitsbrief()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUrlAsdSicherheitsbrief$Response(params: DownloadUrlAsdSicherheitsbrief$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSasUrlDto>> {
    return downloadUrlAsdSicherheitsbrief(this.http, this.rootUrl, params, context);
  }

  /**
   * provides download url for ASD Sicherheitsbrief.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadUrlAsdSicherheitsbrief$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUrlAsdSicherheitsbrief(params: DownloadUrlAsdSicherheitsbrief$Params, context?: HttpContext): Observable<DocumentSasUrlDto> {
    return this.downloadUrlAsdSicherheitsbrief$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSasUrlDto>): DocumentSasUrlDto => r.body)
    );
  }

  /** Path part for operation `deleteAsdSicherheitsbrief()` */
  static readonly DeleteAsdSicherheitsbriefPath = '/documents/asd-sicherheitsbrief/{uuid}';

  /**
   * Delete a ASD Sicherheitsbrief.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAsdSicherheitsbrief()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAsdSicherheitsbrief$Response(params: DeleteAsdSicherheitsbrief$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAsdSicherheitsbrief(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a ASD Sicherheitsbrief.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAsdSicherheitsbrief$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAsdSicherheitsbrief(params: DeleteAsdSicherheitsbrief$Params, context?: HttpContext): Observable<void> {
    return this.deleteAsdSicherheitsbrief$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
