/* tslint:disable */
/* eslint-disable */
export enum Activity {
  URN_ACTIVITIES_CREATE = 'urn:activities:create',
  URN_ACTIVITIES_DELETE = 'urn:activities:delete',
  URN_ACTIVITIES_MAPPING = 'urn:activities:mapping',
  URN_ACTIVITIES_READ = 'urn:activities:read',
  URN_ACTIVITIES_UPDATE = 'urn:activities:update',
  URN_ASD_SICHERHEITSBRIEF_CREATE = 'urn:asd:sicherheitsbrief:create',
  URN_ASD_SICHERHEITSBRIEF_READ = 'urn:asd:sicherheitsbrief:read',
  URN_ASD_SICHERHEITSBRIEF_DELETE = 'urn:asd:sicherheitsbrief:delete',
  URN_NEWSLETTER_CREATE = 'urn:newsletter:create',
  URN_NEWSLETTER_READ = 'urn:newsletter:read',
  URN_NEWSLETTER_UPDATE = 'urn:newsletter:update',
  URN_BILLABLE_SERVICES_COLLECTIVE_CREATE = 'urn:billable:services:collective:create',
  URN_BILLABLE_SERVICES_CREATE = 'urn:billable:services:create',
  URN_BILLABLE_SERVICES_DECLINED_DELETE = 'urn:billable:services:declined:delete',
  URN_BILLABLE_SERVICES_DECLINED_READ = 'urn:billable:services:declined:read',
  URN_BILLABLE_SERVICES_DECLINED_UPDATE = 'urn:billable:services:declined:update',
  URN_BILLABLE_SERVICES_DELETE = 'urn:billable:services:delete',
  URN_BILLABLE_SERVICES_NONBILLED_DELETE = 'urn:billable:services:nonbilled:delete',
  URN_BILLABLE_SERVICES_NONBILLED_READ = 'urn:billable:services:nonbilled:read',
  URN_BILLABLE_SERVICES_NONBILLED_UPDATE = 'urn:billable:services:nonbilled:update',
  URN_BILLABLE_SERVICES_READ = 'urn:billable:services:read',
  URN_BILLABLE_SERVICES_SUBMITTED_CREATE = 'urn:billable:services:submitted:create',
  URN_BILLABLE_SERVICES_SUBMITTED_READ = 'urn:billable:services:submitted:read',
  URN_BILLABLE_SERVICES_UPDATE = 'urn:billable:services:update',
  URN_BUDGET_CREATE = 'urn:budget:create',
  URN_BUDGET_DELETE = 'urn:budget:delete',
  URN_BUDGET_READ = 'urn:budget:read',
  URN_BUDGET_UPDATE = 'urn:budget:update',
  URN_BUSINESS_SECTORS_LIST = 'urn:business:sectors:list',
  URN_CLIENTS_LIST = 'urn:clients:list',
  URN_CONTROLLING_CREATE = 'urn:controlling:create',
  URN_CONTROLLING_DELETE = 'urn:controlling:delete',
  URN_CONTROLLING_QUARTERLY_INVOICE = 'urn:controlling:quarterly:invoice',
  URN_CONTROLLING_QUARTERLY_REPORT = 'urn:controlling:quarterly:report',
  URN_CONTROLLING_READ = 'urn:controlling:read',
  URN_CONTROLLING_SERVICE_OLD = 'urn:controlling:service:old',
  URN_CONTROLLING_UPDATE = 'urn:controlling:update',
  URN_CUSTOMERS_ACTIVATION_UPDATE = 'urn:customers:activation:update',
  URN_CUSTOMERS_ACTIVATION_VALIDATE = 'urn:customers:activation:validate',
  URN_CUSTOMERS_ADDRESS_CREATE = 'urn:customers:address:create',
  URN_CUSTOMERS_ADDRESS_DELETE = 'urn:customers:address:delete',
  URN_CUSTOMERS_ADDRESS_HISTORY_READ = 'urn:customers:address:history:read',
  URN_CUSTOMERS_ADDRESS_READ = 'urn:customers:address:read',
  URN_CUSTOMERS_ADDRESS_UPDATE = 'urn:customers:address:update',
  URN_CUSTOMERS_ALERTPLAN_CREATE = 'urn:customers:alertplan:create',
  URN_CUSTOMERS_ALERTPLAN_DELETE = 'urn:customers:alertplan:delete',
  URN_CUSTOMERS_ALERTPLAN_READ = 'urn:customers:alertplan:read',
  URN_CUSTOMERS_ALERTPLAN_UPDATE = 'urn:customers:alertplan:update',
  URN_CUSTOMERS_BRANCH_OFFICE = 'urn:customers:branch:office',
  URN_CUSTOMERS_BILLABLE_SERVICES = 'urn:customers:billable:services',
  URN_CUSTOMERS_COMMUNICATION_CREATE = 'urn:customers:communication:create',
  URN_CUSTOMERS_COMMUNICATION_DELETE = 'urn:customers:communication:delete',
  URN_CUSTOMERS_COMMUNICATION_READ = 'urn:customers:communication:read',
  URN_CUSTOMERS_COMMUNICATION_UPDATE = 'urn:customers:communication:update',
  URN_CUSTOMERS_CONTRACTS_CREATE = 'urn:customers:contracts:create',
  URN_CUSTOMERS_CONTRACTS_DELETE = 'urn:customers:contracts:delete',
  URN_CUSTOMERS_CONTRACTS_FILE_READ = 'urn:customers:contracts:file:read',
  URN_CUSTOMERS_CONTRACTS_READ = 'urn:customers:contracts:read',
  URN_CUSTOMERS_CONTRACTS_UPDATE = 'urn:customers:contracts:update',
  URN_CUSTOMERS_CREATE = 'urn:customers:create',
  URN_CUSTOMERS_DASHBOARD_CREATE = 'urn:customers:dashboard:create',
  URN_CUSTOMERS_DASHBOARD_DELETE = 'urn:customers:dashboard:delete',
  URN_CUSTOMERS_DASHBOARD_READ = 'urn:customers:dashboard:read',
  URN_CUSTOMERS_DASHBOARD_UPDATE = 'urn:customers:dashboard:update',
  URN_CUSTOMERS_DELETE = 'urn:customers:delete',
  URN_CUSTOMERS_DOCUMENT_CREATE = 'urn:customers:document:create',
  URN_CUSTOMERS_DOCUMENT_DELETE = 'urn:customers:document:delete',
  URN_CUSTOMERS_DOCUMENT_READ = 'urn:customers:document:read',
  URN_CUSTOMERS_DOCUMENT_TEMPLATE_CREATE = 'urn:customers:document:template:create',
  URN_CUSTOMERS_DOCUMENT_TEMPLATE_DELETE = 'urn:customers:document:template:delete',
  URN_CUSTOMERS_DOCUMENT_TEMPLATE_READ = 'urn:customers:document:template:read',
  URN_CUSTOMERS_DOCUMENT_TEMPLATE_UPDATE = 'urn:customers:document:template:update',
  URN_CUSTOMERS_DOCUMENT_UPDATE = 'urn:customers:document:update',
  URN_CUSTOMERS_HEALTH_SAFETY_CREATE = 'urn:customers:health:safety:create',
  URN_CUSTOMERS_HEALTH_SAFETY_DELETE = 'urn:customers:health:safety:delete',
  URN_CUSTOMERS_HEALTH_SAFETY_READ = 'urn:customers:health:safety:read',
  URN_CUSTOMERS_HEALTH_SAFETY_UPDATE = 'urn:customers:health:safety:update',
  URN_CUSTOMERS_READ = 'urn:customers:read',
  URN_CUSTOMERS_RISK_ASSESSMENT_CREATE = 'urn:customers:risk:assessment:create',
  URN_CUSTOMERS_RISK_ASSESSMENT_DELETE = 'urn:customers:risk:assessment:delete',
  URN_CUSTOMERS_RISK_ASSESSMENT_READ = 'urn:customers:risk:assessment:read',
  URN_CUSTOMERS_RISK_ASSESSMENT_UPDATE = 'urn:customers:risk:assessment:update',
  URN_CUSTOMERS_SERVICES_CREATE = 'urn:customers:services:create',
  URN_CUSTOMERS_SERVICES_DELETE = 'urn:customers:services:delete',
  URN_CUSTOMERS_SERVICES_READ = 'urn:customers:services:read',
  URN_CUSTOMERS_SERVICES_UPDATE = 'urn:customers:services:update',
  URN_CUSTOMERS_TRADEASSOCIATION_CREATE = 'urn:customers:tradeassociation:create',
  URN_CUSTOMERS_TRADEASSOCIATION_DELETE = 'urn:customers:tradeassociation:delete',
  URN_CUSTOMERS_TRADEASSOCIATION_READ = 'urn:customers:tradeassociation:read',
  URN_CUSTOMERS_TRADEASSOCIATION_UPDATE = 'urn:customers:tradeassociation:update',
  URN_CUSTOMERS_UPDATE = 'urn:customers:update',
  URN_DOCUMENT_ARCHIVE = 'urn:document:archive',
  URN_DOCUMENT_ASD = 'urn:document:asd',
  URN_DOCUMENT_CREATE = 'urn:document:create',
  URN_DOCUMENT_READ = 'urn:document:read',
  URN_DOCUMENT_TEMPLATE_ARCHIVE = 'urn:document:template:archive',
  URN_DOCUMENT_TEMPLATE_ASD_ARCHIVE = 'urn:document:template:asd:archive',
  URN_DOCUMENT_TEMPLATE_ASD_CREATE = 'urn:document:template:asd:create',
  URN_DOCUMENT_TEMPLATE_ASD_READ = 'urn:document:template:asd:read',
  URN_DOCUMENT_TEMPLATE_ASD_UPDATE = 'urn:document:template:asd:update',
  URN_DOCUMENT_TEMPLATE_CREATE = 'urn:document:template:create',
  URN_DOCUMENT_TEMPLATE_IV_ARCHIVE = 'urn:document:template:iv:archive',
  URN_DOCUMENT_TEMPLATE_IV_CREATE = 'urn:document:template:iv:create',
  URN_DOCUMENT_TEMPLATE_IV_READ = 'urn:document:template:iv:read',
  URN_DOCUMENT_TEMPLATE_IV_UPDATE = 'urn:document:template:iv:update',
  URN_DOCUMENT_TEMPLATE_NATIONWIDE_ARCHIVE = 'urn:document:template:nationwide:archive',
  URN_DOCUMENT_TEMPLATE_NATIONWIDE_CREATE = 'urn:document:template:nationwide:create',
  URN_DOCUMENT_TEMPLATE_NATIONWIDE_READ = 'urn:document:template:nationwide:read',
  URN_DOCUMENT_TEMPLATE_NATIONWIDE_UPDATE = 'urn:document:template:nationwide:update',
  URN_DOCUMENT_TEMPLATE_READ = 'urn:document:template:read',
  URN_DOCUMENT_TEMPLATE_UPDATE = 'urn:document:template:update',
  URN_DOCUMENT_UPDATE = 'urn:document:update',
  URN_EMPLOYEES_LIST = 'urn:employees:list',
  URN_EMPLOYEES_READ = 'urn:employees:read',
  URN_EMPLOYEES_WRITE = 'urn:employees:write',
  URN_EXTERNAL_DOCUMENT_MYSVG_RELEASE = 'urn:external:document:mysvg:release',
  URN_KEYCLOAK_GROUPS_LIST = 'urn:keycloak:groups:list',
  URN_KEYCLOAK_GROUPS_MEMBERS_LIST = 'urn:keycloak:groups:members:list',
  URN_KEYCLOAK_GROUPS_READ = 'urn:keycloak:groups:read',
  URN_KEYCLOAK_GROUPS_WRITE = 'urn:keycloak:groups:write',
  URN_KEYCLOAK_USERS_CREATE = 'urn:keycloak:users:create',
  URN_KEYCLOAK_USERS_DELETE = 'urn:keycloak:users:delete',
  URN_KEYCLOAK_USERS_LIST = 'urn:keycloak:users:list',
  URN_KEYCLOAK_USERS_READ = 'urn:keycloak:users:read',
  URN_KEYCLOAK_USERS_RELATIONS_READ = 'urn:keycloak:users:relations:read',
  URN_KEYCLOAK_USERS_RELATIONS_UPDATE = 'urn:keycloak:users:relations:update',
  URN_KEYCLOAK_USERS_UPDATE = 'urn:keycloak:users:update',
  URN_MEDICAL_CENTER_ASSIGN = 'urn:medical:center:assign',
  URN_MEDICAL_CENTER_CREATE = 'urn:medical:center:create',
  URN_MEDICAL_CENTER_DELETE = 'urn:medical:center:delete',
  URN_MEDICAL_CENTER_READ = 'urn:medical:center:read',
  URN_MEDICAL_CENTER_UPDATE = 'urn:medical:center:update',
  URN_PROJECTS_CREATE = 'urn:projects:create',
  URN_PROJECTS_DELETE = 'urn:projects:delete',
  URN_PROJECTS_READ = 'urn:projects:read',
  URN_PROJECTS_UPDATE = 'urn:projects:update',
  URN_RELEASE_NOTES_CREATE = 'urn:release:notes:create',
  URN_RELEASE_NOTES_DELETE = 'urn:release:notes:delete',
  URN_RELEASE_NOTES_READ = 'urn:release:notes:read',
  URN_RELEASE_NOTES_UPDATE = 'urn:release:notes:update',
  URN_RISK_ASSESSMENT_TEMPLATE = 'urn:risk:assessment:template',
  URN_SECURITY_EXPERT_CREATE = 'urn:security:expert:create',
  URN_SECURITY_EXPERT_DELETE = 'urn:security:expert:delete',
  URN_SECURITY_EXPERT_READ = 'urn:security:expert:read',
  URN_SECURITY_EXPERT_UPDATE = 'urn:security:expert:update',
  URN_STAFF_DASHBOARD_KEYFIGURE_ASD_HOURS = 'urn:staff:dashboard:keyfigure:asd:hours',
  URN_STAFF_DASHBOARD_KEYFIGURE_ASD_HOURS_KOODI = 'urn:staff:dashboard:keyfigure:asd:hours:koodi',
  URN_STAFF_DASHBOARD_KEYFIGURE_ASD_HOURS_TEAM = 'urn:staff:dashboard:keyfigure:asd:hours:team',
  URN_STAFF_DASHBOARD_KEYFIGURE_CUSTOMERS_INACTIVE = 'urn:staff:dashboard:keyfigure:customers:inactive',
  URN_STAFF_DASHBOARD_KEYFIGURE_CUSTOMERS_LAST_VISIT = 'urn:staff:dashboard:keyfigure:customers:last:visit',
  URN_STAFF_DASHBOARD_KEYFIGURE_CUSTOMERS_NEW = 'urn:staff:dashboard:keyfigure:customers:new',
  URN_STAFF_DASHBOARD_KEYFIGURE_CUSTOMERS_NEW_WITHOUT_INITIALLETTER = 'urn:staff:dashboard:keyfigure:customers:new:without:initialletter',
  URN_STAFF_DASHBOARD_KEYFIGURE_CUSTOMERS_NEW_WITHOUT_SECURITYEXPERT = 'urn:staff:dashboard:keyfigure:customers:new:without:securityexpert',
  URN_STAFF_DASHBOARD_KEYFIGURE_CUSTOMERS_REQUESTS = 'urn:staff:dashboard:keyfigure:customers:requests',
  URN_SVG_DATA_CREATE = 'urn:svg:data:create',
  URN_SVG_DATA_DELETE = 'urn:svg:data:delete',
  URN_SVG_DATA_READ = 'urn:svg:data:read',
  URN_SVG_DATA_UPDATE = 'urn:svg:data:update',
  URN_SVG_SERVICES_ADMIN_CREATE = 'urn:svg:services:admin:create',
  URN_SVG_SERVICES_ADMIN_DELETE = 'urn:svg:services:admin:delete',
  URN_SVG_SERVICES_ADMIN_READ = 'urn:svg:services:admin:read',
  URN_SVG_SERVICES_ADMIN_UPDATE = 'urn:svg:services:admin:update',
  URN_TAGS_ADMINISTRATION_CREATE = 'urn:tags:administration:create',
  URN_TAGS_ADMINISTRATION_DELETE = 'urn:tags:administration:delete',
  URN_TAGS_ADMINISTRATION_UPDATE = 'urn:tags:administration:update',
  URN_TAGS_READ = 'urn:tags:read',
  URN_TEAMS_CREATE = 'urn:teams:create',
  URN_TEAMS_DELETE = 'urn:teams:delete',
  URN_TEAMS_READ = 'urn:teams:read',
  URN_TEAMS_UPDATE = 'urn:teams:update',
  URN_TRAINING_CREATE = 'urn:training:create',
  URN_TRAINING_DELETE = 'urn:training:delete',
  URN_TRAINING_READ = 'urn:training:read',
  URN_TRAINING_UPDATE = 'urn:training:update',
  URN_USERS_CREATE = 'urn:users:create',
  URN_USERS_DELETE = 'urn:users:delete',
  URN_USERS_READ = 'urn:users:read',
  URN_USERS_UPDATE = 'urn:users:update',
  URN_SVG_INFO_ADMIN = 'urn:svg:info:admin',
  URN_SVGZ_DOCUMENTS_READ = 'urn:svgz:documents:read',
  URN_SVGZ_DOCUMENTS_CREATE = 'urn:svgz:documents:create',
  URN_SVGZ_DOCUMENTS_DELETE = 'urn:svgz:documents:delete',
  URN_ADMIN_NEWSLETTER = 'urn:admin:newsletter',
  URN_ADMIN_DEFAULTOVERDRAFT = 'urn:admin:defaultoverdraft'
}
