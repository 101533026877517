/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteSvgzDocument } from '../fn/svgz-documents-controller/delete-svgz-document';
import { DeleteSvgzDocument$Params } from '../fn/svgz-documents-controller/delete-svgz-document';
import { DocumentSasUrlDto } from '../models/document-sas-url-dto';
import { downloadUrlSvgzDocument } from '../fn/svgz-documents-controller/download-url-svgz-document';
import { DownloadUrlSvgzDocument$Params } from '../fn/svgz-documents-controller/download-url-svgz-document';
import { getSvgzDocuments } from '../fn/svgz-documents-controller/get-svgz-documents';
import { GetSvgzDocuments$Params } from '../fn/svgz-documents-controller/get-svgz-documents';
import { ResponseSvgzDocumentDto } from '../models/response-svgz-document-dto';
import { SvgzDocumentDto } from '../models/svgz-document-dto';
import { uploadSvgzDocument } from '../fn/svgz-documents-controller/upload-svgz-document';
import { UploadSvgzDocument$Params } from '../fn/svgz-documents-controller/upload-svgz-document';

@Injectable({ providedIn: 'root' })
export class SvgzDocumentsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSvgzDocuments()` */
  static readonly GetSvgzDocumentsPath = '/documents/svgz-documents';

  /**
   * Get SVGZ Documents metadata.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSvgzDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgzDocuments$Response(params?: GetSvgzDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseSvgzDocumentDto>> {
    return getSvgzDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get SVGZ Documents metadata.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSvgzDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSvgzDocuments(params?: GetSvgzDocuments$Params, context?: HttpContext): Observable<ResponseSvgzDocumentDto> {
    return this.getSvgzDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseSvgzDocumentDto>): ResponseSvgzDocumentDto => r.body)
    );
  }

  /** Path part for operation `uploadSvgzDocument()` */
  static readonly UploadSvgzDocumentPath = '/documents/svgz-documents';

  /**
   * Upload a SVGZ Document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadSvgzDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSvgzDocument$Response(params?: UploadSvgzDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<SvgzDocumentDto>> {
    return uploadSvgzDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload a SVGZ Document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadSvgzDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadSvgzDocument(params?: UploadSvgzDocument$Params, context?: HttpContext): Observable<SvgzDocumentDto> {
    return this.uploadSvgzDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<SvgzDocumentDto>): SvgzDocumentDto => r.body)
    );
  }

  /** Path part for operation `downloadUrlSvgzDocument()` */
  static readonly DownloadUrlSvgzDocumentPath = '/documents/svgz-documents/download/{uuid}';

  /**
   * provides download url for SVGZ Document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadUrlSvgzDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUrlSvgzDocument$Response(params: DownloadUrlSvgzDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSasUrlDto>> {
    return downloadUrlSvgzDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * provides download url for SVGZ Document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadUrlSvgzDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUrlSvgzDocument(params: DownloadUrlSvgzDocument$Params, context?: HttpContext): Observable<DocumentSasUrlDto> {
    return this.downloadUrlSvgzDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSasUrlDto>): DocumentSasUrlDto => r.body)
    );
  }

  /** Path part for operation `deleteSvgzDocument()` */
  static readonly DeleteSvgzDocumentPath = '/documents/svgz-documents/{uuid}';

  /**
   * Delete a SVGZ Document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSvgzDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgzDocument$Response(params: DeleteSvgzDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSvgzDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a SVGZ Document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSvgzDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSvgzDocument(params: DeleteSvgzDocument$Params, context?: HttpContext): Observable<void> {
    return this.deleteSvgzDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
